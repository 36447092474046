<template>
  <div class="drawing-group-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @click.native="handleWrite"
    ></PageButton>
    <div class="page-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
        :isDrawingPage="true"
      >
      </SceneSwitchThumbnails>
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="img-area">
        <img :src="bgImgList[currentIndex].image" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  name: "DrawingGroupPage",
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  props: {
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
      startWritingSocketInfo: {},
      clickMenuSocketInfo: {},
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006301,
          data: { value },
          text: "DrawingPage menu按钮 socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    startWritingSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20063,
          data: { value },
          text: "DrawingPage按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickDrawPage", () => {
      this.handleWrite("socket", true);
    });
    this.$bus.$on("clickDrawPageMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickDrawPage");
    this.$bus.$off("clickDrawPageMenu");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index, isFromSocket = false) {
      console.log(index);
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.currentIndex = index - 1;
    },
    // 开始书写
    handleWrite(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.startWritingSocketInfo = {
          index: Math.random(),
        };
      }
      console.log("开始画画");
      this.$bus.$emit("toolBarDrawLine");
    },
  },
};
</script>
<style scoped lang="scss">
.drawing-group-page-container {
  .page-content {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-color: #f5f9ff;
    // background-image: url("../../../../assets/img/03-Backgrounds/level-1-background-blue.svg");
    background-size: cover;
    border-radius: 48px;
    position: relative;
    .menu {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 11;
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 20%;
      height: 17%;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .img-area {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
    }
  }
}
</style>
